import { User } from '../__generated__/graphql'

const isDevUser = (user: User): boolean => {
  return (
    user?.email.endsWith('@worshiponline.com') ||
    user?.email.endsWith('@shalonpalmer.tv')
  )
}

export default isDevUser
